import React, {useState, useEffect} from "react";
import "./service.css"
import { Link } from "react-router-dom";
import waIcon from "../../assets/wa.png"
import fbIcon from "../../assets/fb.png"
import telIcon from "../../assets/te.svg"
import star from "../../assets/star.svg"
import Header from "../header/header";
import { API } from "../../auth/constants";
import axios from "axios";

// const AlreadyHave = ()=> {
//     const { loading, error, data } = useQuery(GET_FRONT_IMAGES);

//     if (loading) {
//         return <div>Loading...</div>;
//     }

//     if (error) {
//         return <div>Error: {error.message}</div>;
//     }

//   const imagesData = data.frontimages.data;
//   console.log(imagesData);

//     return(
//         <div className="container">
//             <div className="image-list">
//                 {imagesData.map((image) => (
//                 <div className="ImagesList" key={image.image.data[0].url}>
//                     <img height="100px"
//                     src={`http://localhost:1337${image.image.data[0].url}`}
//                     alt={image.id}
//                     />
//                 </div>
//                 ))}
//             </div>
//         </div>
//     );
// }





// const RestaurantForm = () => {
//   const [name, setName] = useState('');
//   const [cover, setCover] = useState(null);
//   const [loading, setLoading] = useState(false);

//   const handleFormSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);

//     const data = {};
//     data["caption"] = name
//     console.log(data);
//     const formData = new FormData();

//     if (cover) {
//       formData.append('files.image', cover, cover.name);
//     }

//     formData.append('data', JSON.stringify(data));

//     try {
//       const response = await fetch('http://localhost:1337/api/frontimages', {
//         method: 'post',
//         body: formData,
//       });

//       if (response.status === 200) {
//         console.log('Restaurant created successfully.');
//         console.log(response);
//       } else {
//         console.error('Failed to create restaurant entry.');
//         console.log(response);
//       }
//     } catch (error) {
//       console.error('Error creating restaurant entry:', error);
//     }
//     setLoading(false)
//     window.location.reload()
//   };

//   return (
//     <form onSubmit={handleFormSubmit}>
//       <input
//         type="text"
//         name="caption"
//         value={name}
//         onChange={(e) => setName(e.target.value)}
//         placeholder="Restaurant Name"
//         required
//       />
//       <input
//         type="file"
//         name="pic"
//         required
//         onChange={(e) => setCover(e.target.files[0])}
//       />
//       {loading ? <div className="loader"></div> : <button type="submit">Submit</button>}
      
//     </form>
//   );
// };

const FooterText = () => {
    return (
        <div className="FooterText">
            কাস্টমার সার্ভিস এর কাউকেই আপনার পাসওয়ার্ড দিবেন না। শুধু আপনার ইউজার নেম দিবেন - যদি প্রয়োজন হয়।
        </div>
    )
}




export const CustomerServiceList = ()=>{

    const [customerServiceData, setcustomerServiceData] = useState([]);
    const [loading, setLoading] = useState(false)

    const fetchData = async () => {
        setLoading(true)
        try {

        const response = await axios.get(`${API}/api/services`);

            if (response.status !== 200) {
                throw new Error("Network response was not ok");
            }
        
            const data = response.data;
        setcustomerServiceData(data); // Set the fetched data to the adminData state
        } catch (error) {
        console.error("Error fetching data: ", error);
        }
        setLoading(false)
    };

    useEffect(() => {
        fetchData(); // Call the fetch function when the component is rendered
    }, [])



    const showRating = (rating)=>{
        var stars = []
            for(var i=0; i<rating; i++){
                    stars.push(<img key={i} height="20px" src={star}/>)
            }

            return stars
        
    }

    const shuffledData = [...customerServiceData];
    // console.log(shuffledData);
    for (let i = shuffledData.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledData[i], shuffledData[j]] = [shuffledData[j], shuffledData[i]];
    }

    return loading? (<div className="loader"></div>):(
        <table className="onlymaster theTable ssub">
            <thead>
                <tr>
                    <th className="blackHeading" colSpan={9}>
                        Bazzi365 Customer Service List
                    </th>
                </tr>
                <tr>
                <th>Type</th>
                    <th>Name</th>
                    <th>Id No</th>
                    <th>7* Rating</th>
                    <th>Phone App Link</th>
                    <th>WhatsApp</th>
                    <th>Contact</th>
                    <th>Issue</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className="extraRow1" colSpan={9}>
                        <div>
                        <img src={star} height="20px" alt="star" /> বিস্তারিত ভাবে সব নিয়ম কানুন জানতে আমাদের সাহায্যকারী সাইট টি দেখুনঃ <Link to='https://bazzi365.info/'>Bazzi365.info</Link><img src={star} height="20px" alt="star" />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className="extraRow2" colSpan={9}>
                        .........................................
                    </td>
                </tr>
                {
                    shuffledData.map((data, i)=>{
                        const link = 'https://wa.me/' + data.whatsapp
                        return (
                            <tr key={'cs'+data.id}>
                                <td>{data.type}</td>
                                <td>CS ID :</td>
                                <td>{data.idno}</td>
                                <td className="rating">
                                    {showRating(data.rating)}
                                </td>
                                <td>
                                    <div className="tdFlex">
                                        {data.facebook ? 
                                            <Link to={`${data.facebook}`}><img height="28px" src={fbIcon} alt="" /></Link> : 'X'
                                        }
                                        {data.messenger  ? 
                                            <Link to={`${data.messenger}`}><img height="34px" src={telIcon} alt="" /></Link> : 'X'
                                        }
                                    </div>
                                </td>
                                <td>
                                    <Link to={link}>
                                        <img height="24px" src={waIcon} alt="" />
                                    </Link>
                                </td>
                                <td>
                                    <Link className="greenText" to={link}>{data.whatsapp}</Link>
                                </td>
                                <td>
                                    <Link className="redText" to={'/admin'}>COMPLAIN</Link>
                                </td>

                            </tr>
                        );
                    })
                }
            </tbody>
            
        </table>
      );
}


const Service = ()=> {

    return(
        <div className="container">
            <Header />
            <CustomerServiceList />
            <div style={{height: 20 + 'px'}}></div>
            <FooterText />
        </div>
    );
}
export default Service