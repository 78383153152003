import React from "react";
import { Navigate } from "react-router-dom";
import { getToken } from "../auth/helpers";

const ProtectedRoute = ({ children }) => {
  const token = getToken();

  // If the user does not have a valid JWT token, redirect them to the login page.
  if (!token) {
    return <Navigate to="/login" />;
  }

  // Otherwise, render the protected route.
  return <div>{children}</div>;
};

export default ProtectedRoute;