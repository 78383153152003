import React, {useEffect, useState} from "react";
import Header from "../header/header";
import { getComplainAgent, getComplainAgentId, setComplainAgent, setComplainAgentId } from "../../auth/helpers";
import { API, AgentComplain } from "../../auth/constants";
import { Link } from "react-router-dom";
import waIcon from "../../assets/wat.svg"
import fbIcon from "../../assets/fb.png"
import telIcon from "../../assets/te.svg"
import cs from "../../assets/cs.svg"
import axios from "axios";
import star from "../../assets/star.svg"
import TitleBox from "../titleBox/titleBox";

const GetUpliner = ()=>{

    const id = getComplainAgentId()
    const agent = getComplainAgent()
    // console.log(id, agent);
    const [masterData, setmasterData] = useState([]);
    const [loading, setLoading] = useState(false)

    const fetchData = async () => {
        setLoading(true)
        try {

        const response = await axios.get(`${API}/api/${agent}/${id}`);

        if (response.status !== 200) {
            throw new Error("Network response was not ok");
        }
     
        const data = response.data;
        const liner = `${AgentComplain[agent]}s`
        const linerId = data.upliner
        getUpliner(liner, linerId)
        } catch (error) {
        console.error("Error fetching data: ", error);
        }
        setLoading(false)
    }; 

    const getUpliner = async (liner, linerId) => {
        console.log('getupliner called');
        setLoading(true)
        try {

        const response = await axios.get(`${API}/api/${liner}/${linerId}`);

        if (response.status !== 200) {
            throw new Error("Network response was not ok");
        }
     
        const data = response.data;
        setmasterData(data); // Set the fetched data to the adminData state
        } catch (error) {
        console.error("Error fetching data: ", error);
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchData(); // Call the fetch function when the component is rendered
    }, [])


    const showRating = (rating)=>{
        var stars = []
            for(var i=0; i<rating; i++){
                    stars.push(<img key={i} height="20px" src={star}/>)
            }

            return stars
        
    }


    
    return loading? (<div className="loader"></div>): (
        <div>

            <div className="show" style={{
                width: '900px', margin: '0 auto'
            }}>
                <TitleBox 
                title={
                    <div>
                        <h2 style={{color: 'green', display: 'flex', gap: '20px', alignItems: 'center'}}>Agent Id: {masterData.idno ? masterData.idno : ''} <img height="50px" src={cs} alt="cs" /> {masterData.type}  </h2>
                    </div>
                }
                body={
                    <div style={{
                    paddingBottom: '100px'}}>
                        <div className="flexxx">
                            <div style={{height: '20px'}}></div>
                            <h3>Phone App Link</h3>
                            <div style={{display:'flex', gap: '30px', justifyContent: 'center', alignItems:'center'}}>
                                <div>
                                    {masterData.facebook ? 
                                        <Link to={`${masterData.facebook}`}><img height="80px" src={fbIcon} alt="" /></Link> : ''
                                    }
                                </div>
                                <div>
                                <Link to={'https://wa.me/' + masterData.whatsapp}>
                                            <img height="80px" src={waIcon} alt="WhatsApp Icon" />
                                        </Link>
                                </div>
                                <div>
                                {masterData.messenger  ? 
                                         <Link to={`${masterData.messenger}`}><img height="110px" src={telIcon} alt="" /></Link> : ''
                                    }
                                </div>
                            </div>
                            <h3>Phone Number</h3>
                            <p style={{fontSize: '40px'}}>
                            <Link className="greenText" to={'https://wa.me/' + masterData.whatsapp}>{masterData.whatsapp}</Link>
                            </p>
                        </div>
                    
                    </div>
                }/>
            </div>
        </div>
      );
}

const ComplainPage = ()=> {

    return(
        <div className="ComplainPage">
            <Header />
            <GetUpliner/>
        </div>
    );
}

export default ComplainPage



