import { AUTH_TOKEN } from "./constants";
import { jwtDecode } from "jwt-decode";
    
export const getToken = () => {
  return localStorage.getItem(AUTH_TOKEN);
};

export const setToken = (token) => {
  if (token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
};

export const removeToken = () => {
  localStorage.removeItem(AUTH_TOKEN);
};

export const isTokenExpired = (token) => {
  try {
    const decoded = jwtDecode(token);
    // Check if the token is expired
    return decoded.exp < Date.now() / 1000;
  } catch (err) {
    // In case of decoding error or other issues, consider the token as expired
    return true;
  }
};



export const setComplainAgent = (agent) => {
  localStorage.setItem('ComplainAgent', agent);
}
export const setComplainAgentId = (agentid) => {
  localStorage.setItem('ComplainAgentId', agentid);
}

export const getComplainAgent = () => {
  return localStorage.getItem('ComplainAgent');
}
export const getComplainAgentId = () => {
  return localStorage.getItem('ComplainAgentId');
}

export const removeComplain = ()=> {
  localStorage.removeItem('ComplainAgent');
  localStorage.removeItem('ComplainAgentId');
}

export const setSearchId = (idno) => {
  localStorage.setItem('sid', idno);
}

export const getSearchId = () => {
  return localStorage.getItem('sid');
}

export const removeSearchId = ()=> {
  return localStorage.removeItem('sid');
}