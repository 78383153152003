import React, {useState, useEffect} from "react";
import Modal from 'react-modal'
import EditForm from "./theEditForm";

Modal.setAppElement('#root');

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: 40 + 'px',
      borderRadius: 12+'px',
    },
  };

const AdminModal = ({ isOpen, onRequestClose, apiUrl, id })=> {


    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel="Custom Modal"
        style={customStyles}
      >
        <button onClick={onRequestClose} className="ModalClose">
            X
        </button>
        
          <EditForm url={apiUrl} id={id}/>
      </Modal>
    );
  }
  
  export default AdminModal;