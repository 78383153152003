import React, {useState, useEffect} from "react";
import AdminHeader from "../../components/header/adminHeader";
import { getToken } from "../../auth/helpers";
import { API, AgentComplain } from "../../auth/constants";
import { ShowManageTable } from "./manageCustomerService";
import axios from "axios";
const agent = 'siteadminlists'
const apiUrl = API+'/api/siteadminlists'

const AddASiteAdmin =()=> {

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        type: 'Site Admin',
        name: '',
        facebook: '',
        messenger: '',
        whatsapp: '',
        // Add more fields as needed
      });
      
    
      const handleChange = (e) => {
        
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        // You can now send the formData to your API endpoint using the fetch API or a library like Axios.
        try {
          const response = await axios.post(`${API}/api/siteadminlists`, formData , {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`,
            },
          });
      
          if (response.status === 201) {
            // Data added successfully
          } else {
            setError('There is some problem. Try again');
          }
        } catch (error) {
            setError('Please Check your Network');
        }
        setLoading(false)
        window.location.reload();
      };

    
      return (
        <div className="theMgtForm" style={{background: 'red'}}>
          <h2>Add A Site Admin's Information</h2>
          {error && <div className="error">{error}</div>}
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="type"><span style={{color: 'red'}}>*</span>Type:</label>
              <input
                type="text"
                id="type"
                name="type"
                value={formData.type}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label htmlFor="name"><span style={{color: 'red'}}>*</span>Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label htmlFor="whatsapp"><span style={{color: 'red'}}>*</span>WhatsApp Number</label>
              <input
                type="text"
                id="whatsapp"
                name="whatsapp"
                value={formData.whatsapp}
                onChange={handleChange}
                placeholder="e.g: +123456789"
                required
              />
            </div>
            <div>
              <label htmlFor="facebook">Facebook Link</label>
              <input
                type="text"
                id="facebook"
                name="facebook"
                value={formData.facebook}
                onChange={handleChange}
                placeholder="e.g: https://fb.me/abcdefg...."
              />
            </div>
            <div>
              <label htmlFor="messenger">Messenger Link </label>
              <input
                type="text"
                id="messenger"
                name="messenger"
                value={formData.messenger}
                onChange={handleChange}
                placeholder="e.g: https://m.me/abcdefg...."
              />
            </div>
            <button className="adddatabutton" type="submit" disabled={loading?true:false}>{loading? 'Adding.....': "Add A Site Admin"}</button>
          </form>
        </div>
      );
}

const ManageSiteAdminList = ()=>{
    return(
        <div>
            <AdminHeader />
            <h1 style={{textAlign: "center"}}>Manage Site Admin List</h1>
            <ShowManageTable apiUrl={apiUrl} />
            <div style={{height: 50 + 'px'}}></div>
            <AddASiteAdmin />
            <div style={{height: 50 + 'px'}}></div>
        </div>
    )
}


export default ManageSiteAdminList;