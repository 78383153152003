import React, {useState, useEffect} from "react";
import AdminHeader from "../../components/header/adminHeader";
import { getToken } from "../../auth/helpers";
import { API } from "../../auth/constants";
import { Link } from "react-router-dom";
import fbIcon from "../../assets/fb.png"
import telIcon from "../../assets/te.svg"
import waIcon from "../../assets/wa.png"
import AdminModal from "../../components/modal/adminModal";
import axios from "axios";

const agent = 'services'
const apiUrl = API+'/api/services'

const AddACustomer =()=> {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        type: 'Customer Service',
        idno: 0,
        rating: 1,
        facebook: '',
        messenger: '',
        whatsapp: '',
        name: null,
        // Add more fields as needed
      });
    
      const handleChange = (e) => {
        
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        // You can now send the formData to your API endpoint using the fetch API or a library like Axios.
        try {
          const response = await axios.post(`${API}/api/services`, formData , {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`,
            },
          });
      
          if (response.status === 201) {
            // Data added successfully
          } else {
            setError('There is some problem. Try again');
          }
        } catch (error) {
            setError('Please Check your Network');
        }
        setLoading(false)
        window.location.reload();
      };
    
      return (
        <div className="theMgtForm">
          <h2>Add A Customer Service Information</h2>
          {error && <div className="error">{error}</div>}
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="type"><span style={{color: 'red'}}>*</span>Type:</label>
              <input
                type="text"
                id="type"
                name="type"
                value={formData.type}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label htmlFor="idno"><span style={{color: 'red'}}>*</span>ID Number:</label>
              <input
                type="number"
                id="idno"
                name="idno"
                value={formData.idno}
                onChange={handleChange}
                required
              />
            </div>
            {/* Add more input fields for other data properties */}
            <div>
              <label htmlFor="rating"><span style={{color: 'red'}}>*</span>Rating</label>
              <input
                min={1}
                max={7}
                type="number"
                id="rating"
                name="rating"
                value={formData.rating}
                onChange={handleChange}
                placeholder="Max 7"
                required
              />
            </div>
            <div>
              <label htmlFor="whatsapp"><span style={{color: 'red'}}>*</span>WhatsApp Number</label>
              <input
                type="text"
                id="whatsapp"
                name="whatsapp"
                value={formData.whatsapp}
                onChange={handleChange}
                placeholder="e.g: +123456789"
                required
              />
            </div>
            <div>
              <label htmlFor="facebook">Facebook Link</label>
              <input
                type="text"
                id="facebook"
                name="facebook"
                value={formData.facebook}
                onChange={handleChange}
                placeholder="e.g: https://fb.me/abcdefg...."
              />
            </div>
            <div>
              <label htmlFor="messenger">Messenger Link </label>
              <input
                type="text"
                id="messenger"
                name="messenger"
                value={formData.messenger}
                onChange={handleChange}
                placeholder="e.g: https://m.me/abcdefg...."
              />
            </div>
            <button className="adddatabutton" type="submit" disabled={loading?true:false}>{loading? 'Adding.....': "Add a Customer Service"}</button>
          </form>
        </div>
      );
}

export const ShowManageTable = ({apiUrl})=> {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalIsOpenArray, setModalIsOpenArray] = useState(tableData.map(() => false));
  
  const fetchData = async () => {
    setLoading(true)
    try {
      const response = await axios.get(apiUrl);

          if (response.status !== 200) {
              throw new Error("Network response was not ok");
          }
      
          const data = response.data; 
      setTableData(data); // Set the fetched data to the adminData state
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
    setLoading(false)
  };

  const openModal = (index) => {
    const updatedModalIsOpenArray = [...modalIsOpenArray];
    updatedModalIsOpenArray[index] = true;
    setModalIsOpenArray(updatedModalIsOpenArray);
  };
  
  const closeModal = (index) => {
    const updatedModalIsOpenArray = [...modalIsOpenArray];
    updatedModalIsOpenArray[index] = false;
    setModalIsOpenArray(updatedModalIsOpenArray);
  };

  useEffect(() => {
    fetchData();// Call the fetch function when the component is rendered
  }, [])


  return loading? (<div className="loader"></div>):(
    <div>
      <table className="ShowManageTable" border={1} cellSpacing={0}>
        <thead>
          <tr>
              <th>Type</th>
              <th>Name</th>
              <th>Id No</th>
              <th>Rating</th>
              <th>Facebook</th>
              <th>Messenger</th>
              <th>WhatsApp</th>
              <th>Contact</th>
              <th>Customize</th>
          </tr>
        </thead>
        <tbody>
          {
            tableData.map((data, i)=>{
              const link = 'https://wa.me/' + data.whatsapp
              return (
                <tr key={data.type+data.id}>
                  <td>{data.type}</td>
                  <td>{data.name ? data.name : 'Id:'}</td>
                  
                  <td>{data.idno?data.idno:'X'}</td>
                  <td>
                      {data.rating?data.rating:'X'}
                  </td>
                  <td>
                      {data.facebook? 
                               <Link to={`${"https://"+data.facebook}`}><img height="24px" src={fbIcon} alt="Facebook Icon" /></Link> : 'X'
                          }
                  </td>
                  <td>
                      {data.messenger? 
                          <Link to={`${"https://"+data.messenger}`}><img height="28px" src={telIcon} alt="Messenger Icon" /></Link> : 'X'
                      }
                  </td>
                  <td>
                      <Link to={link}>
                          <img height="24px" src={waIcon} alt="WhatsApp Icon" />
                      </Link>
                  </td>
                  <td>
                      <Link to={link}>{data.whatsapp}</Link>
                  </td>
                  <td>
                    <button onClick={() => openModal(i)}>Edit</button>
                    <AdminModal
                      key={data.type + data.id}
                      isOpen={modalIsOpenArray[i]}
                      onRequestClose={() => closeModal(i)}
                      apiUrl={apiUrl}
                      id={data.id}
                    />
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </div>
  )
}

const ManageCustomerService = ()=>{
    return(
        <div>
            <AdminHeader />
            <h1 style={{textAlign: "center"}}>Manage Customer Service</h1>
            <ShowManageTable apiUrl={apiUrl} />
            <div style={{height: 50 + 'px'}}></div>
            <AddACustomer />
            <div style={{height: 50 + 'px'}}></div>
        </div>
    )
}

export default ManageCustomerService;