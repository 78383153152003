import React, {useEffect, useState} from "react";
import TitleBox from "./titleBox/titleBox";
import { Link } from "react-router-dom";
import waIcon from "../assets/wa.png"
import fbIcon from "../assets/fb.png"
import telIcon from "../assets/te.svg"
import star from "../assets/star.svg"
import Header from "./header/header";
import { API } from "../auth/constants";
import axios from "axios";
import { setComplainAgent, setComplainAgentId } from "../auth/helpers";

export const OneMaster = ()=>{

    const agent = 'masterlists'
    const [masterData, setmasterData] = useState([]);
    const [loading, setLoading] = useState(false)

    const fetchData = async () => {
        setLoading(true)
        try {

        const response = await axios.get(`${API}/api/masterlists`);

          if (response.status !== 200) {
              throw new Error("Network response was not ok");
          }
      
          const data = response.data;
        setmasterData(data); // Set the fetched data to the adminData state
        } catch (error) {
        console.error("Error fetching data: ", error);
        }
        setLoading(false)
    }; 

    useEffect(() => {
        fetchData(); // Call the fetch function when the component is rendered
    }, [])


    const showRating = (rating)=>{
        var stars = []
            for(var i=0; i<rating; i++){
                    stars.push(<img key={i} height="20px" src={star}/>)
            }

            return stars
        
    }

    const shuffledData = [...masterData];;
    for (let i = shuffledData.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledData[i], shuffledData[j]] = [shuffledData[j], shuffledData[i]];
    }
    
    return loading? (<div className="loader"></div>): (
        <table className="onlymaster theTable master">
            <tbody>
                
                {
                    shuffledData.map((data, i)=>{
                        const link = 'https://wa.me/' + data.whatsapp
                        if(i<1){
                            return (
                                <tr key={'abcd'}>
                                    <td>{data.type}</td>
                                    <td>Id:</td>
                                    <td>{data.idno}</td>
                                    <td className="rating">
                                        {showRating(data.rating)}
                                    </td>
                                    <td>
                                    <div className="tdFlex">
                                        {data.facebook ? 
                                            <Link to={`${data.facebook}`}><img height="28px" src={fbIcon} alt="" /></Link> : 'X'
                                        }
                                        {data.messenger  ? 
                                            <Link to={`${data.messenger}`}><img height="34px" src={telIcon} alt="" /></Link> : 'X'
                                        }
                                    </div>
                                    </td>
                                    <td>
                                        <Link to={link}>
                                            <img height="24px" src={waIcon} alt="WhatsApp Icon" />
                                        </Link>
                                    </td>
                                    <td>
                                        <Link className="greenText" to={link}>{data.whatsapp}</Link>
                                    </td>
                                    <td>
                                        <Link className="redText" onClick={()=>{
                                        setComplainAgent(agent)
                                        setComplainAgentId(data.id)
                                    }} to='/complain'>COMPLAIN</Link>
                                    </td>
    
                                </tr>
                            );
                        }
                    })
                }
            </tbody>
            
        </table>
      );
}

const Home = ()=> {

    const qna = [
        {
            q: 'এজেন্ট লিস্টঃ',
            a: `একাউন্ট খুলতে হলে অনলাইন এজেন্ট লিস্ট এ ক্লিক করুন। এজেন্ট লিষ্ট এর এজেন্ট দের সাথে ইউজার দের শুধু মাত্র হোয়াটসাপ এর মাধ্যমে যোগাযোগ করতে হবে। হোয়াটসাপ ছাড়া অন্য কোন মাধ্যমে যোগাযোগ করলে বা লেনদেন করলে তা গ্রহনযোগ্য হবে না। হোয়াটসাপ এ যোগাযোগ করতে হলে এজেন্ট লিস্টে হোয়াটসাপ আইকন উপরে ক্লিক করুন অথবা ফোন নাম্বার টি মোবাইলে সেভ করে তাকে হোয়াটসাপ এ মসেজ পাঠাতে পারবেন। হোয়াটসাপ এপ টি আপনার মোবাইলে আগে থেকেই থাকতে হবে। না থাকলে গুগুল প্লে থেকে ইন্সটল করে নিন।
            `
        },
        {
            q: 'এজেন্ট কয় প্রকারঃ',
            a: `অনলাইন সুপার এজেন্ট লিস্টঃ সুপার এজেন্ট রা, ইউজার একাউন্ট এবং মাষ্টার এজেন্ট একাউন্ট খুলে দিতে পারেন। কোন সুপার এজেন্ট এর নামে অভিযোগ থাকলে - সরাসরি এডমিন কে জানাতে হবে। উপরে মেনু তে এডমিন লিষ্ট দেয়া আছে।
            অনলাইন মাষ্টার এজেন্ট লিস্টঃ অনলাইন মাষ্টার এজেন্ট রা, শুধু ইউজার একাউন্ট একাউন্ট খুলে দিতে পারেন। কোন মাষ্টার এজেন্ট এর নামে অভিযোগ থাকলে - সরাসরি সুপার এজেন্ট এর কাছে অভিযোগ করতে হবে। বিস্তারিত জানতে এই লিঙ্ক এ ক্লিক করুন।
            লোকাল মাষ্টার এজেন্ট লিস্টঃ লোকাল মাষ্টার এজেন্ট রা, শুধু ইউজার একাউন্ট একাউন্ট খুলে দিতে পারেন। কিন্তু তাদের সাথে লেনদেন প্রতিটি ইউজার কে নিজ দায়িত্বে লেনদেন করতে হবে। তাদের নামে কোন অভিযোগ কারো কাছে করা যাবে না।
            লোকাল মাষ্টার এজেন্টঃ এই সব এজেন্ট সাধারনত – নিজের এলাকায় বা পরিচিত দের সাথে লেনদেন করে । যারা আগে বাজি ধরিয়ে দিত, তাদের কেই মুলত লোকাল এজেন্ট দেয়া হয়েছে। লোকাল এজেন্ট রা অনলাইনে আসে না এবং তারা তাদের পরিচয় গোপন রাখতে চায়। লোকাল এজেন্ট দের সাথে অনলাইনে কোন ভাবেই লেনদেন করবেন না, আর করে থাকলে তার দায়ভার পুরোটাই আপনার।`
        },
    ]

    return(
        <div className="home">
            <Header />
            <TitleBox key="master" title="Quick Master Agent:" body={<OneMaster />} />
            <TitleBox key='1' title="কিভাবে একাউন্ট খুলবেনঃ"
                body={
                    <div>
                        <b style={{fontSize: 15 + 'px', letterSpacing: 1 + 'px'}}>BAZZI365.com</b> - এ একাউন্ট করতে হলে আপনার এজেন্ট এর মাধ্যমে একাউন্ট খুলতে হবে। এজেন্ট এর মাধ্যমেই টাকা ডিপোজিট এবং উইথড্র করতে হবে। আপনি যে এজেন্ট এর কাছ থেকে একাউন্ট খুলবেন ঠিক তার সাথেই সব সময় লেনদেন করতে হবে।  কোন এজেন্ট কে টাকা দিবেন এবং কিভাবে তার সাথে লেনদেন করবেন তা বুঝতে হলে আপনার জন্য নিম্নের তথ্য গুলো পড়া জরুরী।
                    </div>
                }
            />
             {
                qna.map((qn, i)=>{
                    return(
                        <TitleBox key={i} title={qn.q} body={qn.a} />
                    )
                })
            }

            
        </div>
    );
}


export default Home