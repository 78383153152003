import React from "react";
import ProtectedRoute from "../util/protectedRoute";
import { useNavigate } from "react-router-dom";
import { removeToken } from "../auth/helpers";
import AdminHeader from "../components/header/adminHeader";
import "./admin.css"

const AdminPanel = ()=> {

    const navigate = useNavigate();

    const handleLogout = ()=> {
        removeToken();
        navigate('/login')
    }

    return (
        <ProtectedRoute>
            <div>
                <AdminHeader/>
                <div className="content">
                    <h1 style={{textAlign: "center"}}>Hello Admin <br />
                        Manage from Navigation
                    </h1>
                    <button className="cocktail" onClick={handleLogout}>Logout</button>
                </div>
            </div>
        </ProtectedRoute>
    );
}

export default AdminPanel