import React, {useState, useEffect} from "react";
import AdminHeader from "../../components/header/adminHeader";
import { getToken } from "../../auth/helpers";
import { API, AgentComplain } from "../../auth/constants";
import { AdminList } from "../../components/admin/admin";
import { ShowManageTable } from "./manageCustomerService";
import axios from "axios";

const agent = 'adminlists'
const apiUrl = API+'/api/adminlists'

const AddAAdmin =()=> {
    const [upliner, setUpliner] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        type: 'Admin',
        name: '',
        idno: 0,
        rating: 1,
        facebook: '',
        messenger: '',
        whatsapp: '',
        upliner: null,
        // Add more fields as needed
      });
    
      const handleChange = (e) => {
        
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };

      const upurl = `${API}/api/${AgentComplain[agent]}s`
      const fetchUpliner = async ()=> {
        try {
          const response = await axios.get(upurl);

          if (response.status !== 200) {
              throw new Error("Network response was not ok");
          }
      
          const data = response.data; 
          setUpliner(data)
        } catch (error) {
          console.error("Error fetching data: ", error);
        }
        setLoading(false)
      }
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
          // Replace 'your-server-api-endpoint' with the actual endpoint for the adminlists route
          const response = await axios.post(`${API}/api/adminlists`, formData , {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`,
            },
          });
      
          if (response.status === 201) {
            // Data added successfully
          } else {
            setError('There is some problem. Try again');
          }
        } catch (error) {
          setError('Please check your network');
        } finally {
          setLoading(false);
          window.location.reload();
        }
      };

      useEffect(() => {
        fetchUpliner();// Call the fetch function when the component is rendered
      }, [])
    
      return (
        <div className="theMgtForm">
          <h2>Add An Admin Information</h2>
          {error && <div className="error">{error}</div>}
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="type"><span style={{color: 'red'}}>*</span>Type:</label>
              <input
                type="text"
                id="type"
                name="type"
                value={formData.type}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label htmlFor="name"><span style={{color: 'red'}}>*</span>Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label htmlFor="idno"><span style={{color: 'red'}}>*</span>ID Number:</label>
              <input
                type="number"
                id="idno"
                name="idno"
                value={formData.idno}
                onChange={handleChange}
                required
              />
            </div>
            {/* Add more input fields for other data properties */}
            <div>
              <label htmlFor="rating"><span style={{color: 'red'}}>*</span>Rating</label>
              <input
                min={1}
                max={7}
                type="number"
                id="rating"
                name="rating"
                value={formData.rating}
                onChange={handleChange}
                placeholder="Max 7"
                required
              />
            </div>
            <div>
              <label htmlFor="whatsapp"><span style={{color: 'red'}}>*</span>WhatsApp Number</label>
              <input
                type="text"
                id="whatsapp"
                name="whatsapp"
                value={formData.whatsapp}
                onChange={handleChange}
                placeholder="e.g: +123456789"
                required
              />
            </div>
            <div>
              <label htmlFor="facebook">Facebook Link</label>
              <input
                type="text"
                id="facebook"
                name="facebook"
                value={formData.facebook}
                onChange={handleChange}
                placeholder="e.g: https://fb.me/abcdefg...."
              />
            </div>
            <div>
              <label htmlFor="messenger">Messenger Link </label>
              <input
                type="text"
                id="messenger"
                name="messenger"
                value={formData.messenger}
                onChange={handleChange}
                placeholder="e.g: https://m.me/abcdefg...."
              />
            </div>
            <div>
              <label htmlFor="upliner"><span style={{color: 'red'}}>*</span>Select Upliner</label>
              <select type='number' required id="upliner" placeholder="Select Upliner" name="upliner" onChange={handleChange}>
              <option value="" defaultValue>Select Upliner</option>
                {
                  upliner.map((liner)=> {
                    return (
                      <option key={liner.id} value={liner.id}>{`Name: ${liner.name}, WA: ${liner.whatsapp}`}</option>
                    )
                  })
                }
            </select>
            </div>
            <button className="adddatabutton" type="submit" disabled={loading?true:false}>{loading? 'Adding.....': "Add an Admin"}</button>
          </form>
        </div>
      );
}

const ManageAdminList = ()=>{
    return(
        <div>
            <AdminHeader />
            <h1 style={{textAlign: "center"}}>Manage Admin List</h1>
            <ShowManageTable apiUrl={apiUrl} />
            <div style={{height: 50 + 'px'}}></div>
            <AddAAdmin />
            <div style={{height: 50 + 'px'}}></div>
        </div>
    )
}

export default ManageAdminList;