import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import client from './client/client';
import {BrowserRouter} from 'react-router-dom'
import { ApolloProvider } from '@apollo/client';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>
);

