import React, { useState } from 'react';
import "./login.css"
import { useNavigate } from 'react-router-dom';
import { setToken } from '../auth/helpers';
import { API } from '../auth/constants';
import axios from 'axios';


function Login() {
  const [formData, setFormData] = useState({
    usernameOrEmail: '',
    password: '',
  });
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  // console.log(formData);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      // Replace 'your-server-api-endpoint' with the actual endpoint for the login route
      const response = await axios.post(`${API}/login`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (response.status === 200) {
        const data = response.data;
        const tokenn = data.token; // Adjust this based on your API response structure
  
        // You can store the token in local storage or cookies for future API requests
        // For security reasons, consider more secure storage options.
        setToken(tokenn);
        setError(null);
        navigate('/adminpanel');
      } else {
        setError('Login failed. Please check your credentials.');
      }
    } catch (error) {
      setError('An error occurred while logging in.');
    } finally {
      setLoading(false);
    }
  };


  return (
    <div className="flexx">
      <div className='loginform'>
      <h2>Login</h2>
      {error && <div className="error">{error}</div>}
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="usernameOrEmail">Username/Email:</label>
          <input
            type="text"
            id="usernameOrEmail"
            name="usernameOrEmail"
            value={formData.usernameOrEmail}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" disabled={loading?true:false}>{loading?'Loading...':'Login'}</button>
      </form>
      </div>
      {/* <div className="infoTable">
        <table>
          <thead>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </thead>
        </table>
      </div> */}
    </div>
  );
}

export default Login;