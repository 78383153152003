import React from "react";
import { Link } from "react-router-dom";
import "./adminHeader.css"
import { useNavigate } from "react-router-dom";
import { removeToken } from "../../auth/helpers";

const AdminHeader = ()=> {

    const navigate = useNavigate();

    const handleLogout = ()=> {
        removeToken();
        navigate('/login')
    }

    return (
        <div className="adminHeader">
            <h1 className="heading">Admin Panel <button className="cocktail" onClick={handleLogout}>Logout</button> </h1>
            <div className="allLinks">
                <div className="link">
                    <Link to='/adminpanel/mgt-customer-service'>Customer Service</Link>
                </div>
                <div className="link">
                    <Link to='/adminpanel/mgt-admin'>Admin</Link>
                </div>
                <div className="link">
                    <Link to='/adminpanel/mgt-ssadmin'>S.S.Admin</Link>
                </div>
                <div className="link">
                    <Link to='/adminpanel/mgt-subadmin'>Sub Admin</Link>
                </div>
                <div className="link">
                    <Link to='/adminpanel/mgt-super'>Super</Link>
                </div>
                <div className="link">
                    <Link to='/adminpanel/mgt-master'>Master</Link>
                </div>
                <div className="link">
                    <Link to="/adminpanel/mgt-site-admin">Site Admin</Link>
                </div>
            </div>
        </div>
    )
}

export default AdminHeader;

