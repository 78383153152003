import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { API } from '../../auth/constants';
import waIcon from "../../assets/wa.png"
import fbIcon from "../../assets/fb.png"
import telIcon from "../../assets/te.svg"
import star from "../../assets/star.svg"

const ResultShowComponent = ({ searchResult }) => {
  const [result, setResult] = useState(null);
  const [link, setLink] = useState(null)
  const [noRes, setNoRes] = useState("No Result")

  const showRating = (rating)=>{
        var stars = []
            for(var i=0; i<rating; i++){
                    stars.push(<img key={i} height="20px" src={star}/>)
            }

            return stars
        
    }

    const fetchData = async () => {
        try {
          const response = await axios.get(`${API}/api/search/${searchResult}`);
          if (response.status !== 404) {
            setResult(response.data[0]);
            setLink(`https://wa.me/${response.data[0].whatsapp}`);
          } else {
            setResult(null)
            setNoRes('No matched Result');
          }
        } catch (error) {
            setResult(null)
          setNoRes('No matched Result');
        }
      };

  useEffect(() => {
    // Fetch and display the result when it changes
    if (searchResult) {
      fetchData()
    }
  }, [searchResult]);

  return (
    <div>
      {result ? (
        <div>

            <table className="theTable admin">
            <thead>
                <tr>
                    <th className="blackHeading" colSpan={9}>
                        Bazzi365 Agent List
                    </th>
                </tr>
                <tr>
                    <th>Type</th>
                    <th>Name</th>
                    <th>Id No</th>
                    <th>Rating</th>
                    <th>Phone App Link</th>
                    <th>WhatsApp</th>
                    <th>Contact</th>
                </tr>
            </thead>
            <tbody>
                
                <tr key={result.id+'admin'}>
                                <td>{result.type}</td>
                                <td>{result.name?result.name:'Id:'}</td>
                                <td>{result.idno}</td>
                                <td className="rating">
                                    {showRating(result.rating)}
                                </td>
                                <td>
                                        <div className="tdFlex">
                                        {result.facebook ? 
                                            <Link to={`${result.facebook}`}><img height="28px" src={fbIcon} alt="" /></Link> : 'X'
                                        }
                                        {result.messenger  ? 
                                            <Link to={`${result.messenger}`}><img height="34px" src={telIcon} alt="" /></Link> : 'X'
                                        }
                                    </div>
                                </td>
                                <td>
                                    <Link to={link}>
                                        <img height="24px" src={waIcon} alt="" />
                                    </Link>
                                </td>
                                <td>
                                    <Link className="greenText" to={link}>{result.whatsapp}</Link>
                                </td>

                            </tr>
            </tbody>
            
            </table>
        </div>
      ) : (
        <h2 style={{textAlign: 'center'}}>{noRes}</h2>
      )}
    </div>
  );
};

export default ResultShowComponent;
