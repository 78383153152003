import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Header.css"
import logo from "../../assets/logo.png"
import homeIcon from "../../assets/homeIcon.svg"
import set from "../../assets/settings.svg"
import search from "../../assets/search.svg"
import { Navigate } from "react-router-dom";
import { removeSearchId, setSearchId } from "../../auth/helpers";
import { useState } from "react";

const Header = ()=> {

    const navigate = useNavigate();
    const location = useLocation();

    const [inputValue, setInputValue] = useState(""); // State to store the input value

    const handleForm = (e) => {
        e.preventDefault();
        const query = inputValue; // Use the input value in the query
        navigate('/search', { state: { query }, replace: true });
    };

    return(
        <header>
            <nav>
                <div className="logo">
                        <div className="image">
                            <Link className="lin1" to='/'><img src={logo} alt="Bazi365 Logo" /></Link> <Link className="lin2" to='https://www.bazzi365.com/'><span className="small shadow">BAZZI365.COM</span></Link>
                        </div>
                        <Link className="lin3" to='/'><span className="shadow reddish">এজেন্ট লিস্ট</span></Link>
                </div>
                <ul className="nav-links">
                    <li><Link to="/"><img height="28px" src={homeIcon} alt="" /></Link></li>
                    <li><Link to="/service"><img src={set} alt="" height="20px"/>&nbsp;Service</Link></li>
                    <li><Link to="/admin"><img src={set} alt="" height="20px"/>&nbsp;Admin</Link></li>
                    <li><Link  to="/senior-sub-admin"><img src={set} alt="" height="20px"/>&nbsp;<span className="ssadm">Senior<br/><span className="noBreak">Sub Admin</span></span></Link></li>
                    <li><Link to="/sub-admin"><img src={set} alt="" height="20px"/>Sub Admin</Link></li>
                    <li><Link to="/super"><img src={set} alt="" height="20px"/>&nbsp;Super</Link></li>
                    <li><Link to="/master"><img src={set} alt="" height="20px"/>&nbsp;Master</Link></li>
                    <li><Link to="/oldnew"><img src={set} alt="" height="20px"/>&nbsp;Old/New</Link></li>
                </ul>
                {location.pathname === '/searh' ? null : (
                    <form className="search" onSubmit={handleForm}>
                        <input
                            className="searchId"
                            type="number"
                            placeholder="Enter Agent Id"
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                        />
                        <button className="searchButton" type="submit"><img height="20px" src={search} alt="" /></button>
                    </form>
                )}
            </nav>
        </header>
    );
}


export default Header