import React from "react";
import Header from "../header/header";


const OldNew = ()=> {

    return(
        <div className="container">
            <Header />
        </div>
    );
}


export default OldNew