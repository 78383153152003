import React, { useEffect } from "react";
import { useState } from "react";
import SearchComponent from "./search";
import ResultShowComponent from "./resultShow";
import { useLocation } from "react-router-dom";
import Header from "../header/header";

const SearchContainer = () => {
    const location = useLocation();
    const [searchResult, setSearchResult] = useState('');

    useEffect(() => {
        if (location.state && location.state.query) {
            setSearchResult(location.state.query);
        }
    }, [location.state]);

    const handleSearch = (searchQuery) => {
        setSearchResult(searchQuery);
    };
  
    return (
      <div>
        <Header />
        {/* Pass handleSearch as the searchFunction prop */}
        {/* Pass searchResult as the searchResult prop */}
        <ResultShowComponent searchResult={searchResult} />
      </div>
    );
  };
  
  export default SearchContainer;
  