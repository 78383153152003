import React from "react";
import "./titleBox.css"
import infoIcon from "../../assets/info.svg"

const TitleBox = (props)=> {

    return (
        <div className="box">
            <div className="title">
                <img height="30px" src={infoIcon} alt="" />
                {props.title}
            </div>
            <div className="body">
                {props.body}
            </div>
        </div>
    );
}

export default TitleBox