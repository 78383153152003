import React, {useEffect, useState} from "react";
import { getToken } from "../../auth/helpers";
import axios from "axios";

const EditForm = ({url, id})=>{
    const [theData, setTheData] = useState([])
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [loading1, setLoading1] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [formData, setFormData] = useState({
        type: '',
        name: '',
        idno: 0,
        rating: 0,
        facebook: '',
        messenger: '',
        whatsapp: '',
        // Add more fields as needed
      });
      // console.log('url: ' + url);
      // console.log('id: ' + id);
      

      const handleChange = (e) => {
        
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };

      const deleteRequest = async (e) => {
        e.preventDefault();
        setLoading2(true)
        // You can now send the formData to your API endpoint using the fetch API or a library like Axios.
        try {
          const response = await axios.delete(`${url}/${id}`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`,
            },
          });
    
          // console.log(response);
          if (response.status === 201) {
            // Data added successfully
          } else {
            setError('There is some problem. Try again');
          }
        } catch (error) {
            setError('Please Check your Network');
        }
        setLoading2(false)
        window.location.reload();
      }

      const putRequest = async (e) => {
        e.preventDefault();
        setLoading1(true)
        // You can now send the formData to your API endpoint using the fetch API or a library like Axios.
        try {
          const response = await axios.put(`${url}/${id}`, formData, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`,
            },
          });
          // console.log(response.status);
    
          if (response.status === 201) {
            // Data added successfully
          } else {
            setError('There is some problem. Try again');
          }
        } catch (error) {
            setError('Please Check your Network');
        }
        setLoading1(false)
        window.location.reload();
      }

      const fetchData = async () => {
        setLoading(true)
        try {

        const response = await axios.get(`${url}/${id}`);

        if (response.status !== 200) {
            throw new Error("Network response was not ok");
        }
     
        const data = response.data;
        setTheData(data);
        setFormData({
            type: data.type || '',
            name: data.name || '',
            idno: data.idno || 0,
            rating: data.rating || 0,
            facebook: data.facebook || '',
            messenger: data.messenger || '',
            whatsapp: data.whatsapp || '',
            // Add more fields as needed
          });
        // console.log('thedata:: ' + theData); // Set the fetched data to the adminData state
        } catch (error) {
        console.error("Error fetching data: ", error);
        }
        setLoading(false)
    }; 
    useEffect(() => {
        fetchData(); // Call the fetch function when the component is rendered
    }, [])

      return loading? (<div className="loader"></div>):(
        <div>
                <div className="theMgtForm">
                    <h2>Edit or Delete Information</h2>
                    {error && <div className="error">{error}</div>}
                    <form>
                        <div>
                          <label htmlFor="type"><span style={{color: 'red'}}>*</span>Type:</label>
                          <input
                              type="text"
                              id="type"
                              name="type"
                              value={formData.type}
                              onChange={handleChange}
                              required
                          />
                        </div>
                        <div>
                          <label htmlFor="type"><span style={{color: 'red'}}>*</span>Name:</label>
                          <input
                              type="text"
                              id="name"
                              name="name"
                              value={formData.name}
                              onChange={handleChange}
                              required
                          />
                        </div>

                        <div>
                          <label htmlFor="idno"><span style={{color: 'red'}}>*</span>ID Number:</label>
                          <input
                              type="number"
                              id="idno"
                              name="idno"
                              value={formData.idno}
                              onChange={handleChange}
                              required
                          />
                        </div>

                        <div>
                          <label htmlFor="rating"><span style={{color: 'red'}}>*</span>Rating</label>
                          <input
                              min={1}
                              max={7}
                              type="number"
                              id="rating"
                              name="rating"
                              value={formData.rating}
                              onChange={handleChange}
                              placeholder="Max 7"
                              required
                          />
                        </div>
                        <div>
                        <label htmlFor="whatsapp"><span style={{color: 'red'}}>*</span>WhatsApp Number</label>
                        <input
                            type="text"
                            id="whatsapp"
                            name="whatsapp"
                            value={formData.whatsapp}
                            onChange={handleChange}
                            placeholder="e.g: +123456789"
                            required
                        />
                        </div>
                        <div>
                        <label htmlFor="facebook">Facebook Link</label>
                        <input
                            type="text"
                            id="facebook"
                            name="facebook"
                            value={formData.facebook}
                            onChange={handleChange}
                            placeholder="e.g: https://fb.me/abcdefg...."
                        />
                        </div>
                        <div>
                        <label htmlFor="messenger">Messenger Link </label>
                        <input
                            type="text"
                            id="messenger"
                            name="messenger"
                            value={formData.messenger}
                            onChange={handleChange}
                            placeholder="e.g: https://m.me/abcdefg...."
                        />
                        </div>
                    </form>
                    <div className="formbtns">
                        <button style={{flex: 1}} onClick={putRequest} className="adddatabutton" disabled={loading1?true:false}>{loading1? 'Saving.....': "Save Changes"}</button>
                        <button style={{flex: 1}} onClick={deleteRequest} className="adddatabutton danger" disabled={loading2?true:false}>{loading2? 'Deleting.....': "DELETE"}</button>
                    </div>
            </div>
          </div>
      );
}

export default EditForm;