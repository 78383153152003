import React, {useState, useEffect} from "react";
import { Link, useNavigate } from "react-router-dom";
import waIcon from "../../assets/wa.png"
import fbIcon from "../../assets/fb.png"
import telIcon from "../../assets/te.svg"
import star from "../../assets/star.svg"
import "./subAdmin.css"
import axios from "axios";
import Header from "../header/header";
import { API } from "../../auth/constants";
import { setComplainAgent, setComplainAgentId } from "../../auth/helpers";

const agent = 'subadminlists'

export const SubAdminList = ()=>{
    const navigate = useNavigate()
    const [subAdminData, setsubAdminData] = useState([]);
    const [loading, setLoading] = useState(false)

    const fetchData = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${API}/api/subadminlists`);

            if (response.status !== 200) {
                throw new Error("Network response was not ok");
            }
        
            const data = response.data;
        setsubAdminData(data); // Set the fetched data to the adminData state
        } catch (error) {
        console.error("Error fetching data: ", error);
        }
        setLoading(false)
    };

    useEffect(() => {
        fetchData(); // Call the fetch function when the component is rendered
    }, [])


    const showRating = (rating)=>{
        var stars = []
            for(var i=0; i<rating; i++){
                    stars.push(<img key={i} height="20px" src={star}/>)
            }

            return stars
        
    }

    const shuffledData = [...subAdminData];

    

    for (let i = shuffledData.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledData[i], shuffledData[j]] = [shuffledData[j], shuffledData[i]];
    }

    

    return loading? (<div className="loader"></div>): (
        <table className="subAdmin theTable">
            <thead>
                <tr>
                    <th className="blackHeading" colSpan={9}>
                        Bazzi365 Sub Admin List
                    </th>
                </tr>
                <tr>
                    <th>Type</th>
                    <th>Name</th>
                    <th>Id No</th>
                    <th>7* Rating</th>
                    <th>Phone App Link</th>
                    <th>WhatsApp</th>
                    <th>Contact</th>
                    <th>Senior Sub Admin</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className="extraRow1" colSpan={9}>
                        <div>
                        <img src={star} height="20px" alt="star" /> বিস্তারিত ভাবে সব নিয়ম কানুন জানতে আমাদের সাহায্যকারী সাইট টি দেখুনঃ <Link to='https://bazzi365.info/'>Bazzi365.info</Link><img src={star} height="20px" alt="star" />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className="extraRow2" colSpan={9}>
                        .........................................
                    </td>
                </tr>
                {
                    shuffledData.map((data, i)=>{
                        const link = 'https://wa.me/' + data.whatsapp
                        return (
                            <tr key={i+34+'av'}>
                                <td>{data.type}</td>
                                <td>{data.name?data.name:'Id:'}</td>
                                <td>{data.idno}</td>
                                <td className="rating">
                                    {showRating(data.rating)}
                                </td>
                                <td>
                                    <div className="tdFlex">
                                        {data.facebook ? 
                                            <Link to={`${data.facebook}`}><img height="28px" src={fbIcon} alt="" /></Link> : 'X'
                                        }
                                        {data.messenger  ? 
                                            <Link to={`${data.messenger}`}><img height="34px" src={telIcon} alt="" /></Link> : 'X'
                                        }
                                    </div>
                                </td>

                                <td>
                                    <Link to={link}>
                                        <img height="24px" src={waIcon} alt="" />
                                    </Link>
                                </td>
                                <td>
                                    <Link className="greenText" to={link}>{data.whatsapp}</Link>
                                </td>
                                <td>
                                    <Link className="redText" onClick={()=>{
                                        setComplainAgent(agent)
                                        setComplainAgentId(data.id)
                                    }} to='/complain'>COMPLAIN</Link>
                                </td>

                            </tr>
                        );
                    })
                }
            </tbody>
            
        </table>
      );
}

const SubAdmin = ()=> {

    return(
        <div className="container">
            <Header />
            <SubAdminList />
        </div>
    );
}


export default SubAdmin