import React, {useEffect} from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Service from "./components/service/service";
import Admin from "./components/admin/admin";
import Master from "./components/master/master";
import Super from "./components/super/super";
import OldNew from "./components/oldNew/oldNew";
import "./App.css"
import SeniorSubAdmin from "./components/seniorSubAdmin/ssubadmin";
import SubAdmin from "./components/subAdmin/subAdmin";
import AdminPanel from "./siteadmin/admin";
import Login from "./siteadmin/login";
import ProtectedRoute from "./util/protectedRoute";
import ManageAdminList from "./siteadmin/pages/manageadminlist";
import ManageSeniorSubAdminList from "./siteadmin/pages/managessadminlist";
import ManageSubAdminList from "./siteadmin/pages/managesubadminlist";
import ManageCustomerService from "./siteadmin/pages/manageCustomerService";
import ManageSuperList from "./siteadmin/pages/manageSuperList";
import ManageMasterList from "./siteadmin/pages/manageMasterList";
import ManageSiteAdminList from "./siteadmin/pages/managesiteadmindata";
import ComplainPage from "./components/complain/complain";
import { removeComplain, removeSearchId, getToken, removeToken, isTokenExpired } from "./auth/helpers";
import SearchContainer from "./components/search/container";

function App() {
  removeComplain()
  removeSearchId()

  useEffect(() => {
    // Check if the token is expired
    const token = getToken();
    if (token && isTokenExpired(token)) {
      // Token has expired, remove it from localStorage
      removeToken();
    }
  }, []);

  return (
      <div className="App">
        <div className="content">
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/service" element={<Service />} />
              <Route path="/search" element={<SearchContainer />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="/senior-sub-admin" element={<SeniorSubAdmin />}/>
              <Route path="/sub-admin" element={<SubAdmin />} />
              <Route path="/super" element={<Super />} />
              <Route path="/master" element={<Master />} />
              <Route path="/oldnew" element={<OldNew />} />
              <Route path="/login" element={<Login />} />
              <Route path="/complain" element={<ComplainPage />} />
              <Route path="/adminpanel" element={<ProtectedRoute><AdminPanel /></ProtectedRoute>}/>
              <Route path="/adminpanel/mgt-customer-service" element={<ProtectedRoute><ManageCustomerService /></ProtectedRoute>} />
              <Route path="/adminpanel/mgt-admin" element={<ProtectedRoute><ManageAdminList /></ProtectedRoute>} />
              <Route path="/adminpanel/mgt-ssadmin" element={<ProtectedRoute><ManageSeniorSubAdminList /></ProtectedRoute>} />
              <Route path="/adminpanel/mgt-subadmin" element={<ProtectedRoute><ManageSubAdminList /></ProtectedRoute>} />
              <Route path="/adminpanel/mgt-super" element={<ProtectedRoute><ManageSuperList /></ProtectedRoute>} />
              <Route path="/adminpanel/mgt-master" element={<ProtectedRoute><ManageMasterList /></ProtectedRoute>} />
              <Route path="/adminpanel/mgt-site-admin" element={<ProtectedRoute><ManageSiteAdminList /></ProtectedRoute>} />

            </Routes>
        </div>
      </div>
  );
}

export default App;
